const Line = require('./summary-line');

class SummaryManager{

    constructor($form, $element){
        this.$form = $form;
        this.$element = $element;
        this.lines = {};
        this.init();
        this.events();
        this.sync();
    }


    init(){
        const $meta = $('meta[name="init-summary"]');
        if(!$meta.length) return;
        const lines = JSON.parse( $meta.attr('content') );
        for(const i in lines){
            if(!lines.hasOwnProperty(i)) continue;
            const line = lines[i];
            if(typeof line.priority === 'undefined') line.priority = 10;
            this.add('init-' + i, new Line(line.title, line.value), line.priority, false)
        }
    }


    events(){
        this.eventDate();
        this.eventTimeSlot();
        this.eventDuration();
        this.eventRecurrence();
        this.eventAddons();
        this.eventSupplies();
        this.eventSelfCleaningOven();
        this.eventNoOfChildren();
        this.eventHoursToCare();
        this.eventYearsOfBirth();
        this.eventDetails();
        this.eventVolume();
    }


    eventVolume(){

        const $input = this.$form.find('input[name="volume"]');
        if( $input.length ){
            const $checked = $input.filter(':checked');
            if( $checked.length ){
                this.add('volume', new Line($checked.val(), $checked.data('price') + 'kr/cubic'), 3);
            }
            $input.change(() => {
                const $checked = $input.filter(':checked');
                this.add('volume', new Line($checked.val(), $checked.data('price') + 'kr/cubic'), 3);
            });
        }

    }

    eventDetails(){
        const $input = $('[name="details"]');
        if( !$input.length ) return;
        this.add('details', new Line('Detaljer', $input.val()), 5);
        $input.keyup(() => this.add('details', new Line('Detaljer', $input.val()), 5));
    }

    eventNoOfChildren(){
        const $input = $('input[name="number_of_children"]');
        if( !$input.length ) return;
        this.add('number_of_children', new Line('Antal barn', $input.val()), 5);
        $input.change(() => this.add('number_of_children', new Line('Antal barn', $input.val()), 5));
    }

    eventHoursToCare(){
        const $input = $('input[name="hours_to_care"]');
        if( !$input.length ) return;
        this.add('hours_to_care', new Line('Timmar att ta hand om', $input.val()), 5.1);
        $input.change(() => this.add('hours_to_care', new Line('Timmar att ta hand om', $input.val()), 5.1));
    }

    eventYearsOfBirth(){
        const $input = $('textarea[name="years_of_birth"]');
        if( !$input.length ) return;
        this.add('years_of_birth', new Line('Födelseår', $input.val()), 5.2);
        $input.keyup(() => this.add('years_of_birth', new Line('Födelseår', $input.val()), 5.2));
    }


    eventDate(){
        const $input = $('input[name="date"]');
        if($input.length){
            this.add('date', new Line("Datum", $input.val()), 3);
            $input.change(() => this.add('date', new Line("Datum", $input.val()), 3));
        }
    }


    eventTimeSlot(){
        const $input = $('select[name="time_slot"]');
        if($input.length){
            this.add('time_slot', new Line("Tidslucka", $input.val()), 4);
            $input.change(() => this.add('time_slot', new Line("Tidslucka", $input.val()), 4));
        }
    }

    eventDuration(){
        const $select = this.$form.find('select[name="duration"]');
        if( $select.length ){

            const $option = $select.children().filter(':selected');
            const text = $option.text().replace(' (rekommendation)', '');
            this.add('duration', new Line('Grundvaraktighet', text));

            $select.change(() => {
                const $option = $select.children().filter(':selected');
                const text = $option.text().replace(' (rekommendation)', '');
                this.add('duration', new Line('Grundvaraktighet', text));
            });
        }
    }

    eventSupplies(){
        const $input = this.$form.find('input[name="supplies"]');
        if( $input.length ){
            const $checked = $input.filter(':checked');
            if( $checked.length ){
                this.add('supplies', new Line($checked.val(), '', 0, 'font-weight-bold'), 30);
            }
            $input.change(() => {
                this.add('supplies', new Line($input.filter(':checked').val(), '', 0, 'font-weight-bold'), 30);
            });
        }
    }


    eventRecurrence(){
        const $input = this.$form.find('input[name="recurrence"]');
        if( $input.length ){
            const $checked = $input.filter(':checked');
            if( $checked.length ){
                this.add('recurrence', new Line($checked.val(), $checked.data('price') + 'kr/hr'), 3);
            }
            $input.change(() => {
                const $checked = $input.filter(':checked');
                this.add('recurrence', new Line($checked.val(), $checked.data('price') + 'kr/hr'), 3);
            });
        }
    }

    eventAddons(){
        const $inputs = this.$form.find('input[name="addons[]"]');
        if( $inputs.length ){
            const summary = this;
            function update(){
                const $checked = $inputs.filter(':checked');

                summary.remove('addons-heading', 20, false);
                for(let i = 0; i < $inputs.length; i++){
                    summary.remove('addons-detail-' + i, 21, false);
                }

                const items = [];
                $checked.each((i, e) => {
                    items.push({
                        title: $(e).val(),
                        value: ( typeof $(e).data('time') === 'undefined' ? '' : '+ ' + $(e).data('time') + ' minuter')
                    });
                    if( i === $checked.length - 1 ){
                        if( items.length > 0){
                            summary.add('addons-heading', new Line('Tillägg', '', 0, 'font-weight-bold'), 20);
                            for(const idx in items){
                                if( !items.hasOwnProperty(idx) ) continue;
                                const item = items[idx];
                                summary.add('addons-detail-' + idx, new Line(item.title, item.value), 21);
                            }
                        }
                    }
                });
            }

            $inputs.change(update);
            update();
        }
    }


    eventSelfCleaningOven(){
        const $inputs = this.$form.find('input[name="self_cleaning_oven"]');
        if($inputs.length){
            this.add('oven', new Line($inputs.filter(':checked').val()));
            $inputs.change(() => this.add('oven', new Line($inputs.filter(':checked').val())));
        }
    }


    /**
     * @param key
     * @param line
     * @param priority
     * @param autoSync
     * @returns Line
     */
    add(key, line, priority = 10, autoSync = true){

        if( typeof line === 'undefined' ) return;
        if( typeof line.render === 'undefined' ) return;

        if(typeof this.lines[priority] === 'undefined'){
            this.lines[priority] = {};
        }

        this.lines[priority][key] = line;

        if(autoSync){
            this.sync();
        }

        return line;
    }


    remove(key, priority = 10, autoSync = true){

        if(typeof this.lines[priority] === 'undefined'){
            return;
        }

        if(typeof this.lines[priority][key] !== 'undefined'){
            delete this.lines[priority][key];
        }

        if( this.lines[priority].length === 0 ){
            delete this.lines[priority];
        }

        if(autoSync){
            this.sync();
        }
    }


    sync(){
        console.log(this.lines);
        this.$element.html(this.render());
    }

    render(){
        const $html = $('<table class="table"></table>');

        for(const i in this.lines){
            if(!this.lines.hasOwnProperty(i)) continue;
            for(const j in this.lines[i]){
                if(!this.lines[i].hasOwnProperty(j)) continue;
                if(typeof this.lines[i][j] === 'undefined') continue;
                $html.append( this.lines[i][j].render() );
            }
        }

        return $html;
    }

}

module.exports = SummaryManager;
