class ContactForm{


    constructor(form){
        this.$form = $(form);
        this.events();
    }


    busy(status){
        if( typeof status === 'undefined' ) status = true;

        this.$form.removeClass('form-state-success form-state-danger form-state-warning form-state-info');

        if( status ){ // busy
            this.$form.addClass('form-state-busy');
            this.$form.find('input,button,textarea').prop('disabled', true);

        }else{ // done
            this.$form.removeClass('form-state-busy');
            this.$form.find('input,button,textarea').prop('disabled', false);
        }

    }


    events(){

        this.$form.submit((e) => {
            e.preventDefault();

            if( !this.validate() ) return;
            const data = this.$form.serialize();
            this.clearErrors();
            this.busy();
            this.submit(data);

        });
    }


    validate(){
        return true;
    }


    submit(data){
        this.response('warning', 'Sändning...');
        axios.post(this.$form.attr('action'), data)
            .then((res) => {
                // console.log(res);
                this.busy(false);
                this.response('success', res.data.message);
            }, (err) => {
                // console.log(err.response);
                this.busy(false);
                this.response('danger', err.response.data.message);
                this.errors( err.response.data.errors );
            });
    }


    response(status, message){
        let $message = '';

        if( typeof status !== 'undefined' && typeof message !== 'undefined' ){

            let iconClass = '';
            switch (status){
                case 'success': iconClass = 'fas fa-check-circle'; break;
                case 'danger': iconClass = 'fas fa-exclamation-circle'; break;
                case 'warning': iconClass = 'fas fa-exclamation-triangle'; break;
                case 'info': iconClass = 'fas fa-question-circle'; break;
            }

            this.$form.addClass('form-state-' + status);

            $message = $('<span class="alert d-inline-block"/>')
                .addClass('alert-' + status)
                .append('<i class="' + iconClass + ' mr-2"></i>') // icon
                .append(message); // message
        }

        this.$form.find('.response').html($message);
    }

    errors(errors){

        if( typeof errors === 'undefined' || errors.length === 0 ){
            return;
        }

        for( const inputName in errors ){
            if( !errors.hasOwnProperty(inputName) ) continue;

            const messages = errors[ inputName ];
            const $input = this.$form.find('[name="' + inputName + '"]');
            $input.parents('label').addClass('is-invalid')
                .append('<span class="text-danger error-line">' + messages[0] + '</span>');
        }

        setTimeout(() => {
            $('html, body').animate({
                scrollTop: $('.is-invalid').first().offset().top - 130
            }, 500);
        }, 10)

    }


    clearErrors(){
        this.$form.find('.is-invalid').removeClass('is-invalid');
        this.$form.find('.error-line').remove();
    }

}




$('.ajax-form').each((i, e) => new ContactForm(e));
