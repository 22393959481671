//===============================================
//Google Map function ===========================
//===============================================


window.initMap = function() {

    if (!document.getElementById('map')){
        return;
    }

    const details = $('.contact-map').data('details');

    const coordMapCenter = details.coordinates;
    const coordMarker = details.coordinates;


    const map = new google.maps.Map(document.getElementById('map'), {
        zoom: 11,
        center: coordMapCenter,
        scrollwheel: false,
        draggable: true,
        styles: [
            {
                "featureType": "administrative.country",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "hue": "#ff0000"
                    }
                ]
            }
        ],
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
            position: google.maps.ControlPosition.LEFT_TOP
        },
        zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM
        },
        panControl: true,

        mapTypeControl: true,
        mapTypeControlOptions: {
            position:google.maps.ControlPosition.RIGHT_TOP
        },


        fullscreenControl:true,
        fullscreenControlOptions:{
            position:google.maps.ControlPosition.RIGHT_TOP
        }
    });


    const $officeAddress = $('' +
        '<div>' +
        '   <div id="iw-content">' +
        '       <div id="iw-notice"></div>' +
        '       <div id="firstHeading" class="firstHeading"></div>' +
        '       <div id="iw-bodyContent"></div>' +
        '   </div>' +
        '</div>');

    $officeAddress.find('.firstHeading').append(details.title);
    $officeAddress.find('#iw-bodyContent')
        .append( $('<p/>').append(details.address) )
        .append(
            details.link.length ?
                $('<a class="map-link" target="_blank">Find Route</a>')
                    .attr('href', details.link)
                : ''
        );

    // const officeAddress = '<div id="iw-content">'+
    //     '<div id="iw-notice"></div>'+
    //     $('<div id="firstHeading" class="firstHeading"/>').append(details.title)[0].outerHTML +
    //     '<div id="iw-bodyContent">'+
    //     $('<p/>').append(details.address)[0].outerHTML +
    //     details.link.length ? $('<a class="map-link" target="_blank">Find Route</a>').attr('href', details.link)[0].outerHTML : ''+
    //     '</div>'+
    //     '</div>';

    console.log($officeAddress.html());

    const infowindow = new google.maps.InfoWindow({
        content: $officeAddress.html()
    });



    const mapmarker = new google.maps.Marker({
        position: coordMarker,
        map: map,
        title: details.title
    });





    mapmarker.addListener('click', function() {
        infowindow.open(map, mapmarker);
    });


};
