class MapsAutocomplete{


    constructor( $form ){

        this.$form = $form;

        if( !this.$form.length ) return;

        this.$inputAddress = this.$form.find('.input-address');
        this.$inputZipCode = this.$form.find('.input-zip');


        this.autocomplete = new google.maps.places.Autocomplete(this.$inputAddress[0], {
            types: ['address'], // Search for precise addresses only.
            fields: ['address_component'],
            componentRestrictions: {country: "se"} // Restrict to country: "Sweden" only.
        });

        this.events();

    }


    events(){
        this.autocomplete.addListener('place_changed', () => this.fillInAddress() );
        this.$inputAddress.focus(this.geolocate);
        this.$form.on('keypress keyup keydown', (e) => e.stopPropagation());
    }


    /**
     * Bias the autocomplete object to the user's geographical location,
     * as supplied by the browser's 'navigator.geolocation' object.
     */
    geolocate(){

        if (navigator.geolocation) {

            navigator.geolocation.getCurrentPosition((position) => {

                const geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                const circle = new google.maps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy,
                });

                this.autocomplete.setBounds(circle.getBounds());

            });
        }
    }


    fillInAddress(){
        const place = this.autocomplete.getPlace();

        for( const i in place.address_components ){
            if( !place.address_components.hasOwnProperty(i) ) continue;

            const component = place.address_components[i];
            if( component.types[0] === 'postal_code' ){
                let zip = component.long_name.split(' ').join('');
                this.$inputZipCode.val( zip ).change();
            }

        }

    }

}


window.initAutocomplete = function(){
    const $forms = $('.address-verifier');
    $forms.each((i, e) => {
        new MapsAutocomplete( $(e) );
    });
};
