class WizardManager{
    // wizard-wrap

    constructor(){
        this.activeIndex = 0;
        this.$wizardItems = $('.wizard-item');

        // this.activate( this.activeIndex, false );
        this.events();
    }

    events(){
        const wizard = this;
        $(document).on('click', '.wizard-title', function(e){
            e.preventDefault();
            const index = $(this).parent('.wizard-item').index();
            // wizard.activate( index );
        });
    }


    activate(index, autoscroll){

        if(typeof autoscroll === 'undefined') autoscroll = true;

        index = index < 0 ? 0 : index;
        index = index >= this.$wizardItems.length ? this.$wizardItems.length - 1 : index;

        this.activeIndex = index;

        this.$wizardItems
            .removeClass('active in-active')
            .addClass('in-active');

        const $item = this.$wizardItems.eq(index)
            .addClass('active');


        if( autoscroll ){
            // Smooth scroll to that element.
            $('html, body').animate({
                scrollTop: $item.offset().top - 100
            }, 800);
        }

    }


    next(){
        this.activate( this.activeIndex + 1 );
    }


    prev(){
        this.activate( this.activeIndex - 1 );
    }

}


if( typeof window.wizardManager === 'undefined' ){
    window.wizardManager = new WizardManager();
}


module.exports = window.wizardManager;
