require('../components/google-maps-places-autocomplete');
const Cookies = require('js-cookie');
const Summary = require('./summary-manager');

class Service{


    static type(){
        return $('meta[name="service-type"]').attr('content');
    };

    constructor(){

        this.attributes();
        this.restoreDefaultFormValues();
        this.events();

        // console.log( this );

    };



    attributes(){

        this.serviceType = Service.type();
        this.addressVerified = false;

        this.$form = $('#form-book-service');

        this.$addressVerifier = this.$form.find('.address-verifier');
        this.$addressVerifierOutput = this.$addressVerifier.find('.address-verifier-output');
        this.$inputAddress = this.$addressVerifier.find('.input-address');
        this.$inputZipCode = this.$addressVerifier.find('.input-zip');
        this.$inputFirstName = this.$form.find('[name="first_name"]');
        this.$inputLastName = this.$form.find('[name="last_name"]');
        this.$inputEmail = this.$form.find('[name="email"]');
        this.$inputPhone = this.$form.find('[name="phone"]');
        this.$inputLandmark = this.$form.find('[name="landmark"]');

        this.summary = new Summary(this.$form, $('#order-summary'));

    }


    events(){

        this.$form.submit((e) => {

            e.preventDefault();

            const data = this.validate();
            if( typeof data !== 'undefined' && data ){
                this.book( data );
            }
        });

        // Initialize Address verifier method.
        if( this.$addressVerifier.length ){
            this.$addressVerifier.find('.verify-button').click((e) => {
                e.preventDefault();
                this.verifyAddress();
            });

            // If a zip code is present by default, check it immediately.
            if( this.$inputZipCode.val().length ){
                this.verifyAddress();
            }

        }


        this.$inputZipCode.change(() => {
            let zip_code = this.$inputZipCode.val().replace(/\s+/g, '');
            zip_code = parseInt( zip_code ) + '';
            zip_code = zip_code.splice(2, 0, ' ');
            this.$inputZipCode.val( zip_code );
            this.$addressVerifier.find('.verify-button').prop_enable();
            this.verifyAddress();
        });


        const $houseAreaSelector = this.$form.find('.house-area-selector');

        // If a house area & duration selector presents.
        if( $houseAreaSelector.length ){
            const $houseAreaInput = $houseAreaSelector.find('[name="area"]');
            const $houseDurationInput = $houseAreaSelector.find('[name="duration"]');

            // Local function to appended "recomemded" text on selected item.
            function setRecommended(value){

                // Set the value -----------------
                $houseDurationInput.val(value).change();

                // Remove "recomended" text from others and add only to the selected.
                const $options = $houseDurationInput.find('option');
                $options.each((i, e) => {
                    const $option = $(e);
                    $option.text( $option.text().replace(' (rekommendation)', '') );

                    // On last iteration
                    if( i === $options.length - 1 ){
                        const $selected = $options.filter(':selected');
                        $selected.text($selected.text() + ' (rekommendation)');
                    }
                });

            }

            // Set Recommended Time by default.
            setRecommended($houseAreaInput.val());

            // Set Recommended Time on changing house area.
            $houseAreaInput.change(() => setRecommended($houseAreaInput.val()));

        }

    }


    verifyAddress(){

        this.busy();
        this.clearErrors();
        this.$addressVerifierOutput.html('').hide();

        const data = {
            address: this.$inputAddress.val(),
            zip_code: this.$inputZipCode.val().split(' ').join(''),
        };

        axios.put(this.$form.attr('action'), data)
            .then((res) => {
                // console.log(res);
                this.busy(false);
                if( res.data.availability ){
                    this.setAddressVerification(true, '<i class="fas fa-check mr-1"></i> Bra service tillgänglig i ditt område.');
                }else{
                    // console.log( this );
                    this.setAddressVerification(false, '<i class="fa fa-exclamation-triangle mr-1"></i> Tyvärr, vi servar inte detta område. Prova någon annanstans.');
                }

            }, (err) => {
                // console.log(err.response.data);
                this.busy(false);
                this.errors( err.response.data.errors );
                this.setAddressVerification(false); // err.response.data.message
            });

    }


    setAddressVerification(verified, message){
        if( typeof message === 'undefined') message = '';

        // console.log('Address Verified:', verified, message);

        this.$addressVerifier.removeClass('address-verifier-state-failed address-verifier-state-success');

        if( verified ){
            this.$addressVerifier.addClass('address-verifier-state-success');
            this.$addressVerifier.find('.verify-button').prop_disable();
        }else{
            this.$addressVerifier.addClass('address-verifier-state-failed');
        }

        this.$addressVerifierOutput.html(message).show();
        this.addressVerified = verified;
    }


    validate(){

        if( this.$addressVerifier.length && !this.addressVerified ){
            this.response('danger', 'Bekräfta din adress för att fortsätta.');
            return false;
        }

        return this.$form.serialize();
    }



    busy(status){
        if( typeof status === 'undefined' ) status = true;

        this.$form.removeClass('form-state-success form-state-danger form-state-warning form-state-info');

        if( status ){ // busy
            this.$form.addClass('form-state-busy');
            this.$form.find('input,button,textarea').prop('disabled', true);

        }else{ // done
            this.$form.removeClass('form-state-busy');
            this.$form.find('input,button,textarea').prop('disabled', false);
        }

    }


    book( data ){

        this.busy();
        this.clearErrors();
        this.response('warning', 'Sändning...');

        axios.post( this.$form.attr('action'), data )
            .then((res) => { // Success
                // console.log(res);
                const order = res.data;
                this.storeDefaultFormValues(order);
                setTimeout(() => {
                    this.busy(false);
                    if(typeof order.id !== 'undefined'){
                        window.location = '/order/' + order.id
                    }
                }, 100);

            }, (err) => { // Error
                this.busy(false);
                this.response('danger', err.response.data.message);
                this.errors( err.response.data.errors );
            });

    };


    response(status, message){
        let $message = '';

        if( typeof status !== 'undefined' && typeof message !== 'undefined' ){

            let iconClass = '';
            switch (status){
                case 'success': iconClass = 'fas fa-check-circle'; break;
                case 'danger': iconClass = 'fas fa-exclamation-circle'; break;
                case 'warning': iconClass = 'fas fa-exclamation-triangle'; break;
                case 'info': iconClass = 'fas fa-question-circle'; break;
            }

            this.$form.addClass('form-state-' + status);

            $message = $('<span class="alert d-inline-block"/>')
                .addClass('alert-' + status)
                .append('<i class="' + iconClass + ' mr-2"></i>') // icon
                .append(message); // message
        }

        this.$form.find('.response').html($message);
    }


    errors(errors){

        if( typeof errors === 'undefined' || errors.length === 0 ){
            return;
        }

        for( const inputName in errors ){
            if( !errors.hasOwnProperty(inputName) ) continue;

            const messages = errors[ inputName ];
            const $input = this.$form.find('[name="' + inputName + '"]');
            $input.parents('label').addClass('is-invalid')
                .append('<span class="text-danger error-line">' + messages[0] + '</span>');
        }

        setTimeout(() => {
            $('html, body').animate({
                scrollTop: $('.is-invalid').first().offset().top - 130
            }, 500);
        }, 10)

    }


    clearErrors(){
        this.$form.find('.is-invalid').removeClass('is-invalid');
        this.$form.find('.error-line').remove();
    }


    getCookie(name, val = ''){
        const c = Cookies.get(name);
        if(typeof c === 'undefined'){
            return val;
        }
        return c;
    }


    setCookie(name, val, options = {}){
        if( typeof name === 'undefined' || typeof val === 'undefined' )
            return;
        Cookies.set(name, val, options);
    }


    restoreDefaultSingleFormValue($input, value){
        if( typeof $input !== 'undefined' && typeof $input.val() !== 'undefined' && $input.val().length === 0 ){
            $input.val(value);
        }
    }

    restoreDefaultFormValues(){
        this.restoreDefaultSingleFormValue( this.$inputFirstName, this.getCookie('first_name') );
        this.restoreDefaultSingleFormValue( this.$inputLastName, this.getCookie('last_name') );
        this.restoreDefaultSingleFormValue( this.$inputEmail, this.getCookie('email') );
        this.restoreDefaultSingleFormValue( this.$inputPhone, this.getCookie('phone') );
        this.restoreDefaultSingleFormValue( this.$inputAddress, this.getCookie('address') );
        this.restoreDefaultSingleFormValue( this.$inputZipCode, this.getCookie('zip_code') );
        this.restoreDefaultSingleFormValue( this.$inputLandmark, this.getCookie('landmark') );

    }


    storeDefaultFormValues(order){
        this.setCookie('first_name', order.first_name, { expires: 365 });
        this.setCookie('last_name', order.last_name, { expires: 365 });
        this.setCookie('email', order.email, { expires: 365 });
        this.setCookie('phone', order.phone, { expires: 365 });
        this.setCookie('address', order.address, { expires: 365 });
        this.setCookie('zip_code', order.zip_code, { expires: 365 });
        this.setCookie('landmark', order.landmark, { expires: 365 });
    }


}

module.exports = Service;
