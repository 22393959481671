(function( $ ){

    $(document).ready(() => {

        $('[data-toggle="tooltip"]').tooltip()

        headerMenu();

        mobile_menu();


        $('.navigation-arrow').click(function (e) {
            e.preventDefault();

            $('html,body').animate({
                scrollTop: 0
            },1500 );
        });


            $('.dropdown-toggle').click(function (e) {
                e.preventDefault();
                e.stopPropagation();
            });



    });

    $(window).scroll(() => {
        headerMenu();
    });

})(jQuery);


function headerMenu() {

    const $header = $('.header');

    const height = $('.count-height').height();

    $('.cover-section').css('margin-top',height);

    const top = $(window).scrollTop();

    if (top >= height){

        if( !$header.hasClass('sticky-header') ){
            $header.addClass('sticky-header')
        }
    }
    else {
        if( $header.hasClass('sticky-header') ){
            $header.removeClass('sticky-header')
        }
    }

}

function mobile_menu() {
    // $('.material-menu').materialmenu({
    //     mobileWidth: 991,
    //     width: 290
    // });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();

        $('.navbar-collapse').addClass('active');
        $('.layer').addClass('active');
        $('body').css('overflow','hidden');
    });

    $('.layer').click(function () {

        $('.navbar-collapse').removeClass('active');
        $('.layer').removeClass('active');
        $('body').css('overflow','auto');
    });
}
