
class Line{

    constructor(title, value = '', count = 0, style = ''){
        this.style = style + '';
        this.title = title + '';
        this.count = count;
        this.value = value + '';
    }


    render(){

        if(this.title === 'undefined' || this.title.length === 0) return '';

        let colSpan = 1;

        if(this.count === 0){
            if(this.value.length === 0) colSpan = 3;
            else colSpan = 2;
        }

        const $html = $('<tr>' +
            '<td class="summary-line-title"></td>' +
            ( colSpan === 1 ? '<td class="summary-line-count" width="80"></td>' : '' ) +
            ( colSpan === 2 ? '<td class="summary-line-value font-weight-bold" width="120"></td>' : '' ) +
        '</tr>');


        if(this.style.length){
            $html.addClass(this.style);
        }

        $html.find('.summary-line-title').html(this.title).attr('colspan', colSpan);
        if( this.count > 0 ){
            $html.find('.summary-line-count').html(this.count + 'x');
        }
        $html.find('.summary-line-value').html(this.value);

        return $html;
    }
}


module.exports = Line;
